<template>
  <div
    :class="{purchased: data.purchased, favorite: data.favorited}"
    class="game-item">
    <div class="inner-padding">
      <div class="inner-content">
        <span
          v-if="data.favorited"
          :style="{ background: `url(${require('@/assets/favorite-badge.svg')})` }"
          class="card-badge favorite"
          @click="action.getContent">
          <i class="fa fa-heart"/>
        </span>
        <span
          v-else-if="data.purchased"
          :style="{ background: `url(${require('@/assets/purchased-badge.svg')})` }"
          class="card-badge purchased"
          @click="action.getContent">
          <i class="fa fa-download"/>
        </span>
        <a @click.prevent="handleDescriptionLink" > <!-- // TODO: Add href for better SEO -->
          <figure>
            <img
              :src="thumbnail"
              :alt="data.title"
              @error="setDefaultThumbnail">
          </figure>
          <p class="game-title">{{ data.title }}</p>
          <ul class="rating">
            <li
              v-for="i in 5"
              :key="i">
              <i
                v-if="i <= rating"
                class="fa fa-star"/>
              <i
                v-else
                class="fa fa-star-o"/>
            </li>
          </ul>
          <div class="info">
            <!-- <span>{{ data.rating ? roundRating(data.rating) : 0 }}<i
              class="fa fa-thumbs-up"
              aria-hidden="true"/></span> -->
            <span class="tags">
              <span>
                <template v-if="tags[0]">
                  {{ tags[0].title }}
                </template>
              </span>
            </span>
            <span v-if="data.content_type === 'downloadable_game'">
              {{ data.downloads ? data.downloads : 0 }}
              <i
                class="fa fa-download"
                aria-hidden="true"/>
            </span>
            <span v-else-if="data.content_type === 'browser_game'">
              {{ data.downloads ? data.downloads : 0 }}
              <i
                class="fa fa-play-circle-o"
                aria-hidden="true"/>
            </span>
          </div>
        </a>
        <button
          class="download-button"
          @click="action.getContent">
          {{ action.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  gtm: {
    // Always send real GTM events (also when using `nuxt dev`)
    enabled: true
  },
  computed: {
    thumbnail() {
      if (this.$store.state.isMobileScreen) {
        if (this.data.thumbnail_mobile && this.data.thumbnail_mobile.length) {
          return this.data.thumbnail_mobile
        } else {
          return require('@/assets/default-images/thumb-mobile.jpg')
        }
      } else {
        if (this.data.thumbnail && this.data.thumbnail.length) {
          return this.data.thumbnail
        } else {
          return require('@/assets/default-images/thumb.jpg')
        }
      }
    },
    tags() {
      return this.$store.getters.categoriesFromTags(this.data.tags)
    },
    rating() {
      return this.data.rated ? this.data.userRate : this.data.rating
    },
    action() {
      let message = null
      let action = null
      switch (this.data.content_type) {
        case 'downloadable_game':
          message = 'download'
          break
        case 'browser_game':
          message = 'play'
          break
        case 'vr_video':
          message = 'playVideo'
          break
        default:
          break
      }
      return {
        title: message ? this.$store.getters.messages(message) : null,
        getContent: () => {
          if (this.data.content_type == 'vr_video')
            this.$store.dispatch('handleGetContentFile', this.data)
          else
            this.$store.dispatch('handleSubscriptionRemember', () => {
              this.$store.dispatch('handleGetContentFile', this.data)
            })
        }
      }
    }
  },
  methods: {
    setDefaultThumbnail(event) {
      if (this.$store.state.isMobileScreen) {
        event.target.src = require('@/assets/default-images/thumb-mobile.jpg')
      } else {
        event.target.src = require('@/assets/default-images/thumb.jpg')
      }
    },
    roundRating(rating) {
      return Math.floor(rating)
    },
    handleDescriptionLink(event) {
      if (this.data.content_type === 'downloadable_game') {
        //this.$gtm.pushEvent({ event: 'pss_view_item', slug: this.data.slug })
        this.$router.push({
          name: 'detail-slug',
          params: { slug: this.data.slug }
        })
      } else {
        this.action.getContent()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

$space-between-cards: 0.5rem;
$padding-inside-card: 0.75rem;
$text-margin: 8px;

.game-item {
  overflow: hidden;
  transition: transform 200ms ease-in;
  margin: 17px 0;
  a {
    cursor: pointer;
  }
  .card-badge {
    display: block;
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0.25em 0.5em;
    width: 3.5em;
    height: 3.5em;
    z-index: 1;
    background-position: top right;
    background-size: contain;
    text-align: right;
    cursor: pointer;
    &.favorite {
      .fa {
        transform: scale(1.25);
      }
    }
  }

  @include s1 {
    &:hover {
      transform: scale(1.1);
      // .download-button {
      //   background-color: $picton-blue;
      // }
    }
  }

  > .inner-padding {
    padding: $space-between-cards;
    @media (max-width: 500px) {
      padding: $space-between-cards * 0.5;
    }
    > .inner-content {
      position: relative;
      padding: $padding-inside-card;
      // border: 1px solid #0887b4;
      @media (max-width: 500px) {
        padding: $padding-inside-card * 0.75;
      }
    }
  }

  .game-title {
    margin: $text-margin 0 0;
    /*text-transform: uppercase;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
    font-size: 3.5vw;
    @include s1 {
      font-size: 1em;
    }
  }

  span {
    i {
      margin-left: 0.75vw;
      @include s1 {
        margin-left: 5px;
      }
    }
  }
  span.tags {
    // flex: 1 1 calc(100% - 6rem);
    flex-grow: 0;
    flex-shrink: 1;
    width: calc(100% - 5rem);
    position: relative;
    &:hover {
      text-decoration: none;
    }
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //// position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: $text-margin;
    white-space: nowrap;
    font-size: 3vw;
    opacity: 0.7;
    position: relative;

    @include s1 {
      font-size: 1em;
    }
  }
  figure {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    padding-top: 66.56%;
  }
  img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 100%;
    top: 0;
    left: 0;
    // @include s1 {
    //   height: 15vw;
    // }
  }

  .download-button {
    border-image: initial;
    border: none;
    display: block;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border-radius: 8px;
    font-size: 5.5vw;
    height: 40px;
    // vertical-align: middle;
    line-height: 40px;
    @include s1 {
      font-size: 1.6em;
    }
  }
}
</style>

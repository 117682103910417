<template>
  <carousel
    ref="carousel"
    :class="{'fadeout': canAdvanceForward}"
    :per-page="2"
    :per-page-custom="[[780, 3], [1050, 4], [1200, 5]]"
    :pagination-enabled="false"
    :navigation-enabled="true"
    :navigation-next-label="navigationNextLabel"
    :navigation-prev-label="navigationPrevLabel"
    class="game-row"
    @pageChange="canAdvanceForward = $refs.carousel.canAdvanceForward">
    <slide
      v-for="game in games.slice(0, 10)"
      :key="game.id">
      <game-card
        v-bind="game"
        :data="game"/>
    </slide>
  </carousel>
</template>

<script>
import debounce from 'lodash.debounce'
import GameCard from './GameCard'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    GameCard,
    Carousel,
    Slide
  },
  props: ['games'],
  data() {
    return {
      canAdvanceForward: false,
      navigationNextLabel: '<i class="fas fa-angle-right fa-lg"></i>',
      navigationPrevLabel: '<i class="fas fa-angle-left fa-lg"></i>'
    }
  },
  mounted() {
    window.addEventListener('resize', debounce(this.setSlideWidth, 18))
    this.setSlideWidth()
  },
  updated() {
    this.canAdvanceForward = this.$refs.carousel.canAdvanceForward
  },
  methods: {
    // Solve issue with card getting way too big.
    // We need to set it here since carousel is calculating slide with dynamically.
    setSlideWidth() {
      if (process.browser) {
        const titles = document.querySelectorAll('.game-item .game-title')
        if (this.$refs.carousel) {
          const slideWidth = this.$refs.carousel.slideWidth - 60
          for (let i = 0; i < titles.length; i += 1) {
            titles[i].style.maxWidth = `${slideWidth}px`
          }
          this.canAdvanceForward = this.$refs.carousel.canAdvanceForward
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

.game-row {
  //width  : 90%;
  margin: 0.5rem auto 1.5rem;
  // position: relative;
  // overflow: hidden;
  @include s2 {
    margin-top: 2rem;
  }
  .VueCarousel-wrapper {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // overflow: visible;
  }
  .VueCarousel-slide {
    // background: red
    flex-basis: inherit;
    // max-width: 300px;
    // flex-basis: unset;
    // flex-grow: 0;
    // flex-shrink: 0;
    // @media only screen and(max-width: 500px){
    //   max-width: 50%;
    // }
  }
  .game-item {
    margin-top: 0;
    margin-bottom: 0;
  }
  .VueCarousel-navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
  }
  // .VueCarousel-navigation{
  //   visibility: visible;
  //   top: 0;
  // }
  button.VueCarousel-navigation-button {
    z-index: 2;
    transform: none;
    display: block;
    opacity: 1;
    visibility: visible;
    transform: none;
    position: relative;
    height: 100%;
    top: 0;
    left: unset;
    right: unset;
    bottom: unset;
    pointer-events: all;
  }
  button.VueCarousel-navigation-prev {
    padding-right: 2.5rem !important;
  }
  button.VueCarousel-navigation-next {
    padding-left: 2.5rem !important;
  }
  // .fadeout:after {
  // opacity : 1;
  // }
  .VueCarousel {
    padding: 2% 0 2% 0;
    // & /deep/ .VueCarousel-wrapper {
    //overflow: visible;
    // }
  }
}

.game-row:after {
  content: '';
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}
</style>
<style lang="scss">
@import '@/sass/resources.sass';

.VueCarousel-navigation--disabled {
  opacity: 0 !important;
}
</style>

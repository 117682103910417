<template>
  <div class="home-slide">
    <h3>{{ data.title }}</h3>
    <p
      v-if="data.description && data.description !=''"
      v-html="data.description"/>
    <no-ssr>
      <game-row :games="data.contents"/>
      <ul slot="placeholder">
        <li
          v-for="game in data.contents"
          :key="game.id">
          <nuxt-link :to="{ name: 'detail-slug', params: { slug: game.slug }}">
            {{ data.title }}
          </nuxt-link>
        </li>
      </ul>
    </no-ssr>
    <hr>
  </div>
</template>

<script>
import GameRow from './GameRow'

export default {
  components: {
    GameRow
  },
  props: ['data'],
  data() {
    return {
      title: '',
      games: {}
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/sass/resources.sass';

$hr-margin: 1.7%;
.home-slide {
  h3,
  p {
    margin: 0 1% 0;
  }
  h3 {
    font-size: 4.4vw;
    font-weight: 700;
    line-height: 1em;
    padding-top: 0.5em;
    @include desktop {
      font-size: 1.8em;
    }
  }
  p {
    padding-top: 0.25em;
    display: block;
  }
}

hr {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  padding: 0;
  margin: $hr-margin 0 $hr-margin 0;
  margin: 0;
}

div:last-child hr {
  display: none;
}
</style>

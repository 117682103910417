<template>
  <carousel
    id="mainSlide"
    :per-page="1"
    :autoplay-timeout="autoplayTimeout"
    autoplay
    loop
    autoplay-hover-pause
    pagination-active-color="$picton-blue"
    pagination-color="#101317"
    @pageChange="disableClick">
    <slide
      v-for="(slide, index) in slides"
      :data="slide"
      :key="slide.media+index">
      <a
        v-if="slide.tipo == 'video'"
        data-fancybox
        @click.prevent="clickSlide(slide.link)">
        <img
          :src="slide.media"
          :alt="slide.title"
          :test="slide.link">
      </a>
      <a
        v-else
        @click.prevent="clickSlide(slide.link)">
        <img
          :src="slide.media"
          :alt="slide.title"
          @error="setDefaultSlide">
      </a>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      items: []
    }
  },
  computed: {
    autoplayTimeout() {
      return this.$store.state.settings.headerAutoplayTimeout || 8000
    },
    slides() {
      if (this.$store.state.isMobileScreen) {
        if (this.$store.state.mainSlide) {
          let slides = this.$store.state.mainSlide.map(slide => {
            let slideData = { ...slide }
            if (slideData.media_mobile && slideData.media_mobile.length) {
              slideData.media = slideData.media_mobile
            } else {
              slideData.media = require('@/assets/default-images/slidehome-mobile.jpg')
            }
            return slideData
          })
          return slides
        }
      } else {
        if (this.$store.state.mainSlide) {
          let slides = this.$store.state.mainSlide.map(slide => {
            let slideData = slide
            if (!slideData.media && slideData.media.length) {
              slideData.media = require('@/assets/default-images/slidehome.jpg')
            }
            return slideData
          })
          return slides
        }
      }
      return this.$store.state.mainSlide
    }
  },
  methods: {
    disableClick() {
      if (process.browser) {
        const links = document.querySelectorAll('.VueCarousel-slide a')
        const removeClick = function removeClick(ev) {
          ev.stopImmediatePropagation()
          ev.preventDefault()
        }
        for (let i = 0; i < links.length; i += 1) {
          const link = links[i]
          link.addEventListener('click', removeClick)
          setTimeout(() => {
            link.removeEventListener('click', removeClick)
          }, 500)
        }
      }
    },
    setDefaultSlide(event) {
      if (this.$store.state.isMobileScreen) {
        event.target.src = require('@/assets/default-images/slidehome-mobile.jpg')
      } else {
        event.target.src = require('@/assets/default-images/slidehome.jpg')
      }
    },
    clickSlide(link) {
      this.$store.dispatch('handleSubscriptionRemember', () => {
        window.location = link
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

#mainSlide {
  .VueCarousel-slide {
    // width      : 100%;
    overflow: hidden;
    position: relative;
    height: auto;
    a {
      display: block;
      position: relative;
      width: 100%;
      // padding-top: 100%;
      img {
        position: relative;
        width: 100%;
        // height: 100vw;
        // max-height: 75vh;
        top: 0;
        left: 0;
        object-fit: contain;
      }
    }

    @include s1 {
      a {
        // padding-top: 30.144%;
        img {
          height: 25vw;
        }
      }
    }
  }

  .VueCarousel-slide img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .VueCarousel-pagination {
    position: absolute;
    bottom: 1%;
  }
}
</style>

<template>
  <div class="header">
    <no-ssr>
      <main-slide/>
      <ul
        v-if="$store.state.mainSlide"
        slot="placeholder">
        <li
          v-for="slide in $store.state.mainSlide"
          :key="slide.media+slide.title">
          <a :href="slide.link">
            <img
              :src="slide.media_mobile"
              alt=" "
              style="visibility: hidden">
            {{ slide.title }}
          </a>
        </li>
      </ul>
    </no-ssr>
    <!--<div
      v-if="medals"
      id="categories-background" >
      <no-ssr>
        <carousel
          id="categories"
          :per-page="3"
          :per-page-custom="[[600, 4], [768, 5], [1000, 6],[1200, 8]]"
          :navigation-next-label="navigationNextLabel"
          :navigation-prev-label="navigationPrevLabel"
          :pagination-enabled="false"
          :navigation-enabled="true">
          <slide
            v-for="(medal) in medals"
            :key="medal.slug"
            class="category">
            <nuxt-link :to="{ name: 'category-id', params: { id: medal.slug }}">
              <img
                :src="medal.media && medal.media[0] ? medal.media[0] : ('//via.placeholder.com/150x150/1b2838/?text=' + encodeURI(medal.title))"
                :alt="medal.title">
              <p>{{ medal.title }}</p>
            </nuxt-link>
          </slide>
        </carousel>
        <ul
          slot="placeholder"
          style="visibility: hidden">
          <li
            v-for="(medal) in medals"
            :key="medal.slug">
            <nuxt-link :to="{ name: 'category-id', params: { id: medal.slug }}">
              <p>{{ medal.title }}</p>
            </nuxt-link>
          </li>
        </ul>
      </no-ssr>
    </div>-->
  </div>
</template>

<script>
import MainSlide from './MainSlide'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    MainSlide,
    Carousel,
    Slide
  },
  data() {
    return {
      navigationNextLabel: '<i class="fas fa-angle-right fa-lg"></i>',
      navigationPrevLabel: '<i class="fas fa-angle-left fa-lg"></i>'
    }
  },
  computed: {
    medals() {
      return this.$store.state.medals
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

$margin-between-categories: 0;

.header {
  @media only screen and (min-width: 768px) {
    // min-height: 695px;
  }

  #categories {
    display: flex;
    justify-content: center;
    width: calc(1280px - 20vw);
    min-width: 60%;
    max-width: calc(100% - 60px);
    padding: 2% 0 1% 0;
    margin: auto auto 2% auto;
  }

  .category {
    text-align: center;
    margin: 0 $margin-between-categories 0 $margin-between-categories;

    img {
      max-width: 100px;
      width: 10vw;
      @media only screen and (max-width: 768px) {
        width: 20vw;
      }
    }
  }

  p {
    font-size: 0.8em;
    margin: 0.4em 0 0 0;

    @include s1 {
      font-size: 1em;
    }
  }

  .VueCarousel-navigation-button {
    padding: 5px !important;
  }
  #categories {
    .VueCarousel {
      padding: 0;
    }
    .VueCarousel-wrapper {
      padding: 1em 0 1.5em;
    }
  }
}
</style>
